<template>
  <div class="goods">
    <div class="image">
      <van-image :src="goods.thumb_image || goods.product.thumb_image || goods.product.image" />
    </div>
    <div class="info">
      <div class="name">{{ goods.name || goods.product.name }}</div>
      <div class="size">规格：1 * {{ goods.size || goods.product.size }}</div>
      <div v-if="showQuantity" class="quantity">数量：{{ goods.volume || goods.quantity }}{{ goods.least_unit }}</div>
      <div v-if="showStorage" class="storage">储位：{{ goods.storage || goods.default_in_warehouse.storage }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    goods: {
      type: Object,
      default() {
        return {}
      }
    },
    showStorage: {
      type: Boolean,
      default() {
        return true
      }
    },
    showQuantity: {
      type: Boolean,
      default() {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.goods {
  display: flex;
  padding: 10px;
  background-color: #fff;
}
.image, .image .van-image {
  width: 100px;
  height: 100px;
}
.info {
  margin-left: 10px;
}
.name {
  font-size: 15px;
  word-break: break-all;
}
.size, .storage, .quantity {
  font-size: 13px;
  color: #989898;
  margin-top: 5px;
}
</style>
