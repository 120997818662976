var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "goods"
  }, [_c('div', {
    staticClass: "image"
  }, [_c('van-image', {
    attrs: {
      "src": _vm.goods.thumb_image || _vm.goods.product.thumb_image || _vm.goods.product.image
    }
  })], 1), _c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.goods.name || _vm.goods.product.name))]), _c('div', {
    staticClass: "size"
  }, [_vm._v("规格：1 * " + _vm._s(_vm.goods.size || _vm.goods.product.size))]), _vm.showQuantity ? _c('div', {
    staticClass: "quantity"
  }, [_vm._v("数量：" + _vm._s(_vm.goods.volume || _vm.goods.quantity) + _vm._s(_vm.goods.least_unit))]) : _vm._e(), _vm.showStorage ? _c('div', {
    staticClass: "storage"
  }, [_vm._v("储位：" + _vm._s(_vm.goods.storage || _vm.goods.default_in_warehouse.storage))]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }