<template>
  <div>
    <my-nav-bar
      title="盘点复核"
      left-text="返回"
      left-arrow
      right-text="仓库"
      @click-right="showWarehouse = true"
    />
    <div class="app-main-container">
      <van-list ref="list" v-model="loading" class="list" :finished="finished" @load="getList">
        <van-empty v-show="showEmpty" description="暂无数据" />
        <div v-for="stocktakingReview in stocktakingReviews" :key="stocktakingReview.id" class="item" @click="toWarehouseGoodsView(stocktakingReview)">
          <van-cell title="盘点人" :value="stocktakingReview.stocktaking_user.name" />
          <van-cell title="盘点时间" :value="stocktakingReview.stocktaking_at" />
          <base-goods :goods="stocktakingReview.goods" />
        </div>
      </van-list>
    </div>
    <van-action-sheet v-model="showWarehouse" :actions="warehouses" @select="chooseWarehouse" />
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import warehouseApi from '@/api/warehouse'
import { getStocktakingReviews } from '@/api/stocktaking-review'
import BaseGoods from '@/components/BaseGoods/base-goods'
export default {
  components: { myNavBar, BaseGoods },
  data() {
    return {
      showWarehouse: false,
      warehouses: [],
      stocktakingReviews: [],
      loading: false,
      finished: false,
      showEmpty: false,
      listQuery: {
        page: 0,
        warehouse_id: localStorage.getItem('stocktaking_review_warehouse_id')
      }
    }
  },
  created() {
    const params = {
      type: 1
    }
    warehouseApi.list(params).then(res => {
      this.warehouses = res.data
    })
  },
  methods: {
    getList() {
      if (!this.listQuery.warehouse_id) {
        this.stocktakingReviews = []
        this.showEmpty = true
        this.loading = false
        this.finished = true
        return
      }
      this.listQuery.page++
      getStocktakingReviews(this.listQuery).then(res => {
        this.stocktakingReviews = this.stocktakingReviews.concat(res.data.list)
        this.showEmpty = this.stocktakingReviews.length === 0
        this.loading = false
        this.finished = res.data.list.length < 10
      })
    },
    chooseWarehouse(warehouse) {
      localStorage.setItem('stocktaking_review_warehouse_id', warehouse.id)
      this.listQuery.warehouse_id = warehouse.id
      this.showWarehouse = false
      this.reload()
    },
    reload() {
      this.stocktakingReviews = []
      this.finished = false
      this.showEmpty = false
      this.listQuery.page = 0
      this.$refs.list.check()
    },
    toWarehouseGoodsView(stocktakingReview) {
      this.$router.push({
        path: '/warehouse-goods-detail',
        query: {
          goods_id: stocktakingReview.goods_id,
          is_stocktkaing_review: 1,
          stocktaking_plan_id: stocktakingReview.stocktaking_plan_id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  padding: 10px;
}
.item {
  margin-bottom: 10px;
}
.item:nth-last-child(2) {
  margin-bottom: 0;
}
</style>
