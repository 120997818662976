var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "盘点复核",
      "left-text": "返回",
      "left-arrow": "",
      "right-text": "仓库"
    },
    on: {
      "click-right": function clickRight($event) {
        _vm.showWarehouse = true;
      }
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, [_c('van-list', {
    ref: "list",
    staticClass: "list",
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.getList
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, [_c('van-empty', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showEmpty,
      expression: "showEmpty"
    }],
    attrs: {
      "description": "暂无数据"
    }
  }), _vm._l(_vm.stocktakingReviews, function (stocktakingReview) {
    return _c('div', {
      key: stocktakingReview.id,
      staticClass: "item",
      on: {
        "click": function click($event) {
          return _vm.toWarehouseGoodsView(stocktakingReview);
        }
      }
    }, [_c('van-cell', {
      attrs: {
        "title": "盘点人",
        "value": stocktakingReview.stocktaking_user.name
      }
    }), _c('van-cell', {
      attrs: {
        "title": "盘点时间",
        "value": stocktakingReview.stocktaking_at
      }
    }), _c('base-goods', {
      attrs: {
        "goods": stocktakingReview.goods
      }
    })], 1);
  })], 2)], 1), _c('van-action-sheet', {
    attrs: {
      "actions": _vm.warehouses
    },
    on: {
      "select": _vm.chooseWarehouse
    },
    model: {
      value: _vm.showWarehouse,
      callback: function callback($$v) {
        _vm.showWarehouse = $$v;
      },
      expression: "showWarehouse"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }